// App.js
//

import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { firestore } from './firebaseSetup';
import DataDisplay from './DataDisplay';
import LineChart from './LineChart';
import { format, addDays } from 'date-fns';
import './App.css';

function App() {
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );

  useEffect(() => {
    const fetchData = async () => {
      const dataRef = collection(firestore, 'omie_data_charts');
      const q = query(dataRef, where('date', '==', selectedDate));
      const unsubscribe = onSnapshot(
        q,
        (snapshot) => {
          const chartData = snapshot.docs.map((doc) => ({
            date_key: doc.id,
            values: doc.data().values,
          }));
          setData(chartData);
        },
        (error) => {
          console.error('Error fetching data:', error);
        }
      );

      return () => {
        unsubscribe();
      };
    };

    fetchData();
  }, [selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const changeDate = (delta) => {
    const currentDate = new Date(selectedDate);
    const newDate = addDays(currentDate, delta);
    setSelectedDate(format(newDate, 'yyyy-MM-dd'));
  };

  return (
    <div className="App">
	<div className="header-container">
		<header className="App-header">
			<h1>Mercado Tarifas Indexadas</h1>
		</header>
		<div className="date-picker-container">
			<div className="date-picker-buttons">
		  		<button onClick={() => changeDate(-1)}>{"<"}</button>
			</div>
			<div className="date-picker">
				<label htmlFor="date-selector"></label>
				<input
				    type="date"
				    id="date-selector"
				    value={selectedDate}
				    onChange={handleDateChange}
				/>
			</div>
			<div className="date-picker-buttons">
		  		<button onClick={() => changeDate(1)}>{">"}</button>
			</div>
	      </div>
      </div>
      <div className="chart-container">
        <DataDisplay selectedDate={selectedDate} />
      </div>
    </div>
  );
}

export default App;

