// DataDisplay.js

import React from 'react';
import useData from './useData';

const DataDisplay = ({ selectedDate }) => {
  const { data: documents, status } = useData(selectedDate);

  if (status === 'loading') return <div>Carregar...</div>;

  if (status === 'error' ) return <div>Este dia n&atilde;o tem dados disponiveis</div>;

  return (
    <div>
      {documents.map(doc => (
        <div key={doc.id}>
          <h3>Data Actual: {doc.currentDate}</h3>
          <p>M&eacute;dia Mensal MWh: {doc.monthlyAverageMwh} &euro;</p>
          <p>Tend&ecirc;ncia: {doc.trend}</p>
          <h4>Dados do Dia</h4>
          <ul>
            <li>Data: {doc.spotValue.date}</li>
            <li>Valor do dia: {doc.spotValue.currentValue} &euro;</li>
	    <li>Valor Amanh&atilde;: {doc.spotValue && doc.spotValue.tomorrow ? doc.spotValue.tomorrow : "N/A"} &euro;</li>
            <li>Varia&ccedil;&atilde;o: {doc.spotValue.percentage_variation} %</li>
          </ul>
          <h4>Ciclo de Fatura&ccedil;&atilde;o Simples</h4>
          <ul>
            <li>Data Inicio: {doc.billingCycle.startDate}</li>
            <li>Data Fim: {doc.billingCycle.endDate}</li>
            <li>M&eacute;dia MWh ciclo: {doc.billingCycle.averageKwhCycle} &euro;</li>
            <li>kWh: {doc.billingCycle.kwh} &euro;</li>
            <li style={{ color: doc.billingCycle.kwhTAR < 0 ? 'green' : doc.billingCycle.kwhTAR <= 10 ? 'orange' : 'red' }}>kWh(TAR): {doc.billingCycle.kwhTAR} &euro;</li>
          </ul>
	{doc.billingCycleSemanal && doc.billingCycleSemanal.startDate && doc.billingCycleSemanal.endDate ? (
	  <>
		  <h4>Ciclo de Fatura&ccedil;&atilde;o Semanal</h4>
		  <ul>
		    <li>Data Inicio: {doc.billingCycleSemanal.startDate}</li>
		    <li>Data Fim: {doc.billingCycleSemanal.endDate}</li>
		    <h5>Vazio</h5>
		    <li>M&eacute;dia MWh ciclo Vazio: {doc.billingCycleSemanal.averageKwhCycleVazio} &euro;</li>
		    <li>kWh Vazio: {doc.billingCycleSemanal.kwhVazio} &euro;</li>
		    <li style={{ color: doc.billingCycleSemanal.kwhVazioTAR < 0 ? 'green' : doc.billingCycleSemanal.kwhVazioTAR <= 10 ? 'orange' : 'red' }}>kWh Vazio(TAR): {doc.billingCycleSemanal.kwhVazioTAR} &euro;</li>
		    <h5>Cheio/Pico</h5>
		    <li>M&eacute;dia MWh ciclo Cheio/Pico: {doc.billingCycleSemanal.averageKwhCycleCheio} &euro;</li>
		    <li>kWh Cheio/Pico: {doc.billingCycleSemanal.kwhCheio} &euro;</li>
		    <li style={{ color: doc.billingCycleSemanal.kwhCheioTAR < 0 ? 'green' : doc.billingCycleSemanal.kwhCheioTAR <= 10 ? 'orange' : 'red' }}>kWh Cheio/Pico(TAR): {doc.billingCycleSemanal.kwhCheioTAR} &euro;</li>
		  </ul>
	  </>
	): null }
        </div>
      ))}
    </div>
  );
};

export default DataDisplay;

