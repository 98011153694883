// useData.js
import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebaseSetup'; // import your Firestore instance here

const useData = (selectedDate) => {
  const [documents, setDocuments] = useState([]);
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    const selectedDateObj = new Date(selectedDate);
    const year = selectedDateObj.getFullYear();
    const month = ('0' + (selectedDateObj.getMonth() + 1)).slice(-2);
    const day = ('0' + selectedDateObj.getDate()).slice(-2);
    const formattedDate = `${day}-${month}-${year}`;
    console.log('Formatted ' + formattedDate);

    const docRef = doc(firestore, 'omie_data', formattedDate);
    const fetchData = async () => {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setDocuments([{ ...docSnap.data(), id: docSnap.id }]);
        setStatus('success');
      } else {
        console.log('No such document!');
        setStatus('error');
      }
    };

    fetchData();
  }, [selectedDate]);

  return { data: documents, status };
};

export default useData;

