// LineChart.js
import React from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';


class LineChart extends React.Component {
  chartRef = React.createRef();
  chartInstance = null;

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart() {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { data } = this.props;

    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    this.chartInstance = new Chart(myChartRef, {
      type: "line",
      data: {
        // Map time and value fields from data array to Chart.js format
        labels: data.map(d => d.time),
        datasets: [
          {
            data: data.map(d => d.value),
            borderColor: "#3e95cd",
            fill: false
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            distribution: 'linear',
          },
        },
      }
    });
  }

  render() {
    return (
      <div>
        <canvas ref={this.chartRef} />
      </div>
    );
  }
}

export default LineChart;

